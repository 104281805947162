import React, { Component } from 'react';
import '../custom.css';

export class Tos extends Component {
    static displayName = Tos.name;

    render() {
        return (
            <div>
                Terms of service
            </div>
        );
    }
}
