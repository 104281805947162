import React, { Component } from 'react';
import '../custom.css';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <div className="logo" />
                <br/>
                <h1 className="bold">COMING SOON</h1>
                <h2 className="light">Our website is under construction</h2>
            </div>
        );
    }
}
